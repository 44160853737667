import { useMutation, gql } from '@apollo/client';

const UseAddComments = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($planId: ID!, $comments: String!) {
        addCommentsToPlan(planId: $planId, comments: $comments)
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    }
  );
};

export default UseAddComments;