import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
//import usePaginatedLessons from '../../../../Model/Lessons/List/ByPlanId'
import useLessons from './Model/useLessons'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Resume from './Resume'
import Lessons from './Lessons'
import Payments from './Payments'
import CheckIns from './CheckIns'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import * as moment from 'moment'
import useTranslation from '../../../../i18n/useTranslation'

const TabNames = {
  resume: 'resume',
  lesson: 'lesson',
  payments: 'payments',
  checkIns: 'checkIns',
}

const InfoModal = (props) => {
  const { info, topicKeys, groupInfo, refetch = () => {} } = props
  const {translate} = useTranslation()
  //plan-lesson (individual)
  const [getPlanLessons, { data: lessonsQuery, loading }] = useLessons()
  useEffect(() => {
    if(info?.id) {
      getPlanLessons({
        variables : {
          planId: info.id,
          status: ['scheduled', 'canceled', 'pending', 'finished'],
          limit: 0
        }
      })
    }
  }, [info.id, getPlanLessons])
  //const { data: lessonsQuery, loading } = usePaginatedLessons({
  //  planId: info.id,
  //  status: ['scheduled', 'canceled', 'pending', 'finished'],
  //  limit: 0
  //})
  //descarta orientation
  const lessons = useMemo(() => {
    let validLessons = lessonsQuery?.lessons?.docs?.filter((les) => ['plan-lesson', 'group-lesson'].includes(les.kind)) || []
    return {
      totalDocs: validLessons?.length || 0,
      docs: validLessons
    }
  }, [lessonsQuery])
  
  //group lessons}
  const [getGroupLessons, { data: groupLessonsQuery, loading: groupLoading }] = useLessons()  
  useEffect(() => {
    if(info?.groupId) {
      getGroupLessons({
        variables: {
          planId: info.groupId,
          status: ['scheduled', 'pending', 'finished'],
          limit: 0
        }
      })
    }
  }, [info.groupId, getGroupLessons])
  //const { data: groupLessonsQuery, groupLoading } = usePaginatedLessons({
  //  planId: info.groupId,
  //  status: ['scheduled', 'canceled', 'pending', 'finished'],
  //  limit: 0
  //})
  //descarta orientation
  const groupLessons = useMemo(() => {
    let validLessons = groupLessonsQuery?.lessons?.docs?.filter((les) => ['plan-lesson', 'group-lesson'].includes(les.kind)) || []
    return {
      totalDocs: validLessons?.length || 0,
      docs: validLessons
    }
  }, [groupLessonsQuery])

  const lessonParser = (lessons) => {
    return !isEmpty(lessons) && lessons.totalDocs
      ? lessons.docs
        .map((item) => {
          return {
            ...item,
            status: startCase(item.status),
            lessonTime: moment(item.startDate),
            lessonEndTime: moment(item.endDate),
            attendants: [
              { name: `${item.educator.firstName} ${item.educator.lastName}` }
            ].concat(
              item.kind === 'group-lesson' 
                ? item.students.map(s => ({ name: `${s?.firstName} ${s?.lastName}` })) 
                : [{ name: `${item?.student?.firstName} ${item?.student?.lastName}` }]
            )
          }
        })
        .sort((a, b) => a.lessonTime.valueOf() - b.lessonTime.valueOf())
      : []
  }
  const parsedLessons = lessonParser(lessons)
  const parsedGroupLessons = lessonParser(groupLessons)
  const [activeTab, setActiveTab] = useState('resume')
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <div className='mt-4 px-4 d-flex flex-column '>
      {
        activeTab !== TabNames.checkInsNotes && (
          <Nav className='w-100 znk-scroll-horizontal flex-nowrap'>
            <NavItem className='nav-title mb-2 mr-2' active={activeTab === TabNames.resume}>
              <NavLink
                onClick={() => {
                  toggle(TabNames.resume)
                }}
              >
                Summary
              </NavLink>
            </NavItem>
            <NavItem className='nav-title mr-2' active={activeTab === TabNames.lesson}>
              <NavLink
                onClick={() => {
                  toggle(TabNames.lesson)
                }}
              >
                Lessons
              </NavLink>
            </NavItem>
            <NavItem className='nav-title mr-2' active={activeTab === TabNames.checkIns}>
              <NavLink
                onClick={() => {
                  toggle(TabNames.checkIns)
                }}
              >
                {translate("program.plan.infoModal.checkInSection")}
              </NavLink>
            </NavItem>
            <NavItem className='nav-title mr-2' active={activeTab === TabNames.payments}>
              <NavLink
                onClick={() => {
                  toggle(TabNames.payments)
                }}
              >
                Payments
              </NavLink>
            </NavItem>
          </Nav>
        )
      }
      <TabContent activeTab={activeTab} className='px-4'>
        <TabPane tabId={TabNames.resume}>
          <Resume groupInfo={groupInfo} topicKeys={topicKeys} info={info} lessons={parsedLessons} lessonsLoading={loading} groupLoading={groupLoading} groupLessons={parsedGroupLessons} refetch={refetch} />
        </TabPane>
        <TabPane tabId={TabNames.lesson}>
          <Lessons topicKeys={topicKeys} currentUserId={info.studentUser.studentUserId} 
            lessons={parsedLessons.sort((a, b) => a.lessonTime.valueOf() - b.lessonTime.valueOf())} lessonsLoading={loading} />
        </TabPane>
        <TabPane tabId={TabNames.payments}>
          <Payments info={info} />
        </TabPane>
        <TabPane tabId={TabNames.checkIns}>
          <CheckIns plan={info} refetch={refetch} />
        </TabPane>
      </TabContent>
    </div>
  )
}
InfoModal.propTypes = {
  lessons: PropTypes.array
}
InfoModal.defaultProps = {
  lessons: []
}
export default InfoModal
