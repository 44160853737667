import React, { useEffect, useState } from 'react';
import {
  Modal,
  IconClose,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components';
import useTranslation from '../../../../../i18n/useTranslation';
import { useSelector, useDispatch } from 'react-redux';
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const';
import UseAddComments from '../../scheduled/Model/useAddComments';
import { showAlert } from '../../../../../common/Alert/util';

const AddComments = (props) => {
  const { form, setForm, breakWidth = '', isEdit = false, isCreate = false, refetch = () => {} } = props;
  const dispatch = useDispatch();
  const [comments, setComments] = useState('');
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.addComments',
      data: !isOpen,
    });
  };

  useEffect(() => {
    if (form?.comments) {
      setComments(form.comments);
    }
  }
  , [form.comments]);

  const { translate } = useTranslation();
  const { isOpen } = useSelector((state) => state.modal.plan.addComments);
  const [addCommentsToPlan, { loading }] = UseAddComments(
    () => {
      showAlert({
        status: 'success',
        text: 'Comments added successfully'
      }, dispatch)
      refetch()
      toggle()
    },
    (error) => {
      showAlert({
        status: 'error',
        text: error.message
      }, dispatch)
    }
  );
  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex modal-header-height-responsive'>
            <div className='d-flex flex-column justify-content-start'>
              <span className='text-title font-weight-bold h2'>
                {translate('modal.newplan.addcomments.title')}
              </span>
            </div>
            <div className='pt-1'>
              <IconClose
                className='d-flex align-self-start'
                size={20}
                onClick={toggle}
              />
            </div>
          </div>
        }
      >
        <div className='m-0 px-4 py-0'>
          <div className='m-0 pb-5 px-0 my-0'>
            <textarea
              className='znk-input w-100 p-3'
              style={{
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                height: '200px'
              }}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder={!isEdit && !form?.comments ? 'No comments' : 'Type your comments here'}
              readOnly={!isEdit && !isCreate}
            />
          </div>
        </div>
        {
          isEdit && (
            <StickyButton
              className='p-0 m-0'
              label={translate('modal.newplan.comments.save')}
              color='#696CFF'
              onClickButtonFunction={() => {
                setForm({
                  ...form,
                  comments,
                })
                toggle();
              }}
            />
          )
        }
        {
          isCreate && (
            <StickyButton
              className='p-0 m-0'
              label={translate('modal.newplan.comments.save')}
              color='#696CFF'
              onClickButtonFunction={() => {
                addCommentsToPlan(
                  { variables: { planId: form?.id, comments } }
                );
                setForm({
                  ...form,
                  comments,
                });
              }}
              activity={loading}
            />
          )
        }
      </Modal>
    </>
  );
};

export default AddComments;
